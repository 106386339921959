/* 4. Landing Page ================================= */
.mainContainer {
	background: url(../assets/heroes/bgCloud.jpg);
	background-size: cover;
	position: relative;
	margin: 90px auto 0px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 6px;
}

@media (min-width: 768px) {
	.mainContainer {
		margin: 160px auto 0px;
	}
}

@media (min-width: 1440px) {
	.mainContainer {
		margin: 122px auto 0px;
	}
}

.mainContainer .projectSelect {
	background: black;
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: 85px;
	margin: -20px auto 0px;
	padding: 0px 10px;
	z-index: 1000;
	box-shadow: 0px 1px 16px -3px black;
}

@media (min-width: 768px) {
	.mainContainer .projectSelect {
		height: 160px;
		padding-left: 10%;
		padding-right: 10%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projectSelect {
		height: 130px;
		padding-left: 25%;
		padding-right: 25%;
		margin-top: 18px;
	}
}

.mainContainer .projectSelect .selectBox {
	position: relative;
	margin: 25px auto 0px;
	width: 23%;
	height: auto;
	color: white;
	font-family: niveau-grotesk, sans-serif;
	font-size: 24px;
	font-weight: 400;
	text-align: center;
	cursor: pointer;
}

@media (min-width: 768px) {
	.mainContainer .projectSelect .selectBox {
		font-size: 42px;
		margin-top: 40px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projectSelect .selectBox {
		font-size: 39px;
		margin-top: 20px;
		width: 24%;
	}
}

.mainContainer .projectSelect #allBox {
	transition: .7s;
}

.mainContainer .projectSelect #allBox.allHighlighted {
	transition: .5s;
}

.mainContainer .projectSelect #designBox {
	transition: .7s;
}

.mainContainer .projectSelect #designBox.designHighlighted {
	transition: .5s;
}

.mainContainer .projectSelect #devBox {
	transition: .7s;
}

.mainContainer .projectSelect #devBox.devHighlighted {
	transition: .5s;
}

.mainContainer .projectSelect .underSelect {
	position: relative;
	margin: 8px auto;
	height: 2px;
	background: white;
	transition: .4s;
	opacity: 0;
	cursor: pointer;
}

@media (min-width: 768px) {
	.mainContainer .projectSelect .underSelect {
		margin: 11px auto;
		height: 3px;
	}
}

.mainContainer .projectSelect .underAll {
	width: 38%;
	transform: scaleX(0);
	transition: .4s;
}

@media (min-width: 768px) {
	.mainContainer .projectSelect .underAll {
		width: 25%;
	}
}

.mainContainer .projectSelect .underAll.allSelected {
	transform: scaleX(1);
	background: #26ffba;
	opacity: 1;
}

.mainContainer .projectSelect .underDesign {
	width: 85%;
	transform: scaleX(0);
	transition: .4s;
}

@media (min-width: 768px) {
	.mainContainer .projectSelect .underDesign {
		width: 70%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projectSelect .underDesign {
		width: 60%;
	}
}

.mainContainer .projectSelect .underDesign.designSelected {
	transform: scaleX(1);
	background: #26ffba;
	opacity: 1;
}

.mainContainer .projectSelect .underDev {
	width: 48%;
	transform: scaleX(0);
	transition: .4s;
}

@media (min-width: 768px) {
	.mainContainer .projectSelect .underDev {
		width: 41%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .projectSelect .underDev {
		width: 36%;
	}
}

.mainContainer .projectSelect .underDev.devSelected {
	transform: scaleX(1);
	background: #26ffba;
	opacity: 1;
}

.mainContainer .designContainer {
	position: relative;
	margin: 62px auto 0px;
	width: 100%;
	padding: 0 1.5%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	transition: .5s;
}

@media (min-width: 768px) {
	.mainContainer .designContainer {
		margin: 140px auto 0px;
		padding: 0 .75%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .designContainer {
		margin: 149px auto 0px;
		padding: 0 .50%;
	}
}

.mainContainer .designContainer .project {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background: url(../assets/thumbs/destinyBG.jpg) no-repeat black;
	background-size: cover;
	background-position-x: center;
	background-position-y: -40px;
	margin: 6px auto 0px;
	width: 100%;
	height: 280px;
	cursor: pointer;
	transition: .5s;
}

@media (min-width: 768px) {
	.mainContainer .designContainer .project {
		height: 420px;
		background-position-y: -150px;
		margin: 7px auto 0px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .designContainer .project {
		height: 550px;
	}
}

.mainContainer .designContainer .project a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-decoration: none;
}

.mainContainer .designContainer .project a .projectLabel {
	position: absolute;
	bottom: 0px;
	padding: 20px 0px;
	width: 100%;
	height: auto;
	font-family: niveau-grotesk, sans-serif;
	font-size: 29px;
	line-height: 52px;
	font-weight: 400;
	text-align: center;
	background: rgba(255, 255, 255, 0.95);
	text-decoration: none;
	color: black;
}

@media (min-width: 768px) {
	.mainContainer .designContainer .project a .projectLabel {
		padding: 18px 0px;
		font-size: 30px;
		letter-spacing: .5px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .designContainer .project a .projectLabel {
		transform: scaleY(0);
		transform-origin: 0 100%;
		transition: .4s;
		opacity: 0;
	}
}

.mainContainer .designContainer .project a .hideDevLabel {
	display: none;
}

.mainContainer .designContainer .project a .hideDesignLabel {
	display: none;
}

.mainContainer .designContainer .project a .hideMusicLabel {
	display: none;
}

@media (min-width: 1440px) {
	.mainContainer .designContainer .project:hover .projectLabel {
		transform: scaleY(1);
		transition: .4s;
		opacity: .95;
	}
}

.mainContainer .designContainer .projectOne {
	margin: 8px auto 0px;
}

.mainContainer .designContainer .projectTwo {
	background: url(../assets/thumbs/fishLoop.gif) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectTwo.filterOn {
	border: 2px solid orange;
}

.mainContainer .designContainer .projectThree {
	background: url(../assets/thumbs/touchsource.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectFour {
	background: url(../assets/thumbs/aspens.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectFive {
	background: url(../assets/thumbs/elderscrolls.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectSix {
	background: url(../assets/thumbs/caroLoop.gif) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectSeven {
	background: url(../assets/thumbs/fizzMap.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectEight {
	background: url(../assets/thumbs/mediacracy.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectNine {
	background: url(../assets/thumbs/starbucks.svg) no-repeat white;
	background-size: 60%;
	background-position-x: 50%;
}

@media (min-width: 768px) {
	.mainContainer .designContainer .projectNine {
		background-size: contain;
	}
}

.mainContainer .designContainer .projectTen {
	background: url(../assets/thumbs/motionReel.gif) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectEleven {
	background: url(../assets/thumbs/intoTheStorm.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectTwelve {
	background: url(../assets/thumbs/heisman.jpg) no-repeat;
	background-size: cover;
	background-position: 50% -50px;
}

@media (min-width: 1440px) {
	.mainContainer .designContainer .projectTwelve {
		background-position: center;
	}
}

.mainContainer .designContainer .projectThirteen {
	background: url(../assets/thumbs/thrones.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectFourteen {
	background: url(../assets/thumbs/bengal.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

.mainContainer .designContainer .projectFifteen {
	background: url(../assets/thumbs/sirius.jpg) no-repeat;
	background-size: cover;
	background-position: center;
}

@media (min-width: 768px) {
	.mainContainer .designContainer .small {
		width: 49.65%;
		margin: 8px 0 0 0;
	}
}

@media (min-width: 1440px) {
	.mainContainer .designContainer .small {
		width: 49.75%;
	}
}

@media (min-width: 1440px) {
	.mainContainer .designContainer .nudgeLeft {
		margin-left: .5%;
	}
}

@media (min-width: 768px) {
	.mainContainer .designContainer .makeFullSize {
		width: 100%;
		transition: .5s;
	}
}

.mainContainer .designContainer .hideDevProject {
	transition: .5s;
	opacity: 0;
	width: 0px !important;
	height: 0px !important;
}

.mainContainer .designContainer .hideDesignProject {
	transition: .5s;
	opacity: 0;
	width: 0px !important;
	height: 0px !important;
}

.mainContainer .designContainer .hideMusicProject {
	transition: .5s;
	opacity: 0;
	width: 0px !important;
	height: 0px !important;
}

.mainContainer .contactButton {
	position: relative;
	margin: 50px auto 40px;
	width: 49%;
	height: 67px;
	text-transform: uppercase;
	text-align: center;
	font-family: niveau-grotesk, sans-serif;
	font-size: 25px;
	font-weight: 500;
	letter-spacing: 1px;
	color: white;
	border: 4px solid white;
	cursor: pointer;
}

@media (min-width: 768px) {
	.mainContainer .contactButton {
		width: 28%;
		font-size: 39px;
		height: 95px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .contactButton {
		margin: 80px auto 200px;
		width: 17%;
		font-size: 47px;
		height: 121px;
		transition: .4s;
	}
	.mainContainer .contactButton:hover {
		background: rgba(255, 255, 255, 0.4);
		transition: .4s;
	}
}

.mainContainer .contactButton a {
	position: absolute;
	width: 100%;
	height: 100%;
	padding-top: 16px;
	top: 0;
	left: 0;
	color: white;
	text-decoration: none;
}

@media (min-width: 768px) {
	.mainContainer .contactButton a {
		padding-top: 22px;
	}
}

@media (min-width: 1440px) {
	.mainContainer .contactButton a {
		padding-top: 34px;
	}
}
