$mainFont: niveau-grotesk, sans-serif;
$textColor: #17405B;



/* 4. Landing Page ================================= */

.mainContainer {
	background: url(../assets/heroes/bgCloud.jpg);
	background-size: cover;
	position: relative;
	margin: 90px auto 0px;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 6px;	

	@media (min-width: 768px) {
		margin: 160px auto 0px;
	}

	@media (min-width: 1440px) {
		margin: 122px auto 0px;
	}

	.projectSelect {
		background: black;
		position: fixed;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		height: 85px;
		margin: -20px auto 0px;
		padding: 0px 10px;
		z-index: 1000;
		box-shadow: 0px 1px 16px -3px black;

		@media (min-width: 768px) {
			height: 160px;
			padding-left: 10%;
			padding-right: 10%;
		}

		@media (min-width: 1440px) {
		    height: 130px;
		    padding-left: 25%;
		    padding-right: 25%;	
		    margin-top: 18px;
		}

		.selectBox {
			position: relative;
			margin: 25px auto 0px;
			width: 23%;
			height: auto;
			color: white;
			font-family: $mainFont;
			font-size: 24px;
			font-weight: 400;
			text-align: center;	
			cursor: pointer;

			@media (min-width: 768px) {
				font-size: 42px;
				margin-top: 40px;
			}	

			@media (min-width: 1440px) {
		        font-size: 39px;
		 		margin-top: 20px;
		 		width: 24%;
			}
		}

		#allBox {
			transition: .7s;

			&.allHighlighted {
				// color: #26ffba !important;
				transition: .5s;
			}
		}

		#designBox {
			transition: .7s;

			&.designHighlighted {
				// color: #984aff !important;
				transition: .5s;
			}
		}

		#devBox {
			transition: .7s;

			&.devHighlighted {
				// color: #ff24bd !important;
				transition: .5s;
			}
		}

		.underSelect {
			position: relative;
			margin: 8px auto;
			height: 2px;
			background: white;
			transition: .4s;
			opacity: 0;
			cursor: pointer;

			@media (min-width: 768px) {
			    margin: 11px auto;
			    height: 3px;
			}
		}

		.underAll {
			width: 38%;
			transform: scaleX(0);
			transition: .4s;

			@media (min-width: 768px) {
				width: 25%;
			}

			&.allSelected {
				transform: scaleX(1);
				background: #26ffba;
				opacity: 1;
			}
		}

		.underDesign {
			width: 85%;
			transform: scaleX(0);
			transition: .4s;

			@media (min-width: 768px) {
				width: 70%;
			}

			@media (min-width: 1440px) {
				width: 60%;
			}

			&.designSelected {
				transform: scaleX(1);
				background: #26ffba;
				opacity: 1;
			}
		}

		.underDev {
			width: 48%;
			transform: scaleX(0);
			transition: .4s;

			@media (min-width: 768px) {
				width: 41%;
			}

			@media (min-width: 1440px) {
				width: 36%;
			}

			&.devSelected {
				transform: scaleX(1);
				background: #26ffba;
				opacity: 1;
			}
		}
	}


	.designContainer {
		position: relative;
		margin: 62px auto 0px;
		width: 100%;
		padding: 0 1.5%;
		height: auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		transition: .5s;

		@media (min-width: 768px) {
			margin: 140px auto 0px;
			padding: 0 .75%;
		}

		@media (min-width: 1440px) {
			margin: 149px auto 0px;
			padding: 0 .50%;
		}

		.project {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			background: url(../assets/thumbs/destinyBG.jpg) no-repeat black;
			background-size: cover;
			background-position-x: center;
			background-position-y: -40px;
			margin: 6px auto 0px;
			width: 100%;
			height: 280px;
			cursor: pointer;
			transition: .5s;
			// background: white;

			@media( min-width: 768px ) {
				height: 420px;
				background-position-y: -150px;
				margin: 7px auto 0px;
			}

			@media (min-width: 1440px) {
	            height: 550px;
			}


			a {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				text-decoration: none;

				.projectLabel {
				    position: absolute;
				    bottom: 0px;
				    padding: 20px 0px;
				    width: 100%;
				    height: auto;
				    font-family: niveau-grotesk, sans-serif;
				    font-size: 29px;
				    line-height: 52px;
				    font-weight: 400;
				    text-align: center;
				    background: rgba(255, 255, 255, 0.95);
				    text-decoration: none;
				    color: black;

				    @media( min-width: 768px ) {
					    padding: 18px 0px;
					    font-size: 30px;
					    letter-spacing: .5px;
					}

					@media( min-width: 1440px ) {
						transform: scaleY(0);
						transform-origin: (0 100%);
						transition: .4s;
						opacity: 0;
					}
				}

				.hideDevLabel {
					display: none;
				}

				.hideDesignLabel {
					display: none;
				}

				.hideMusicLabel {
					display: none;
				}
			}

			@media( min-width: 1440px ) {
				&:hover {
					.projectLabel {
						transform: scaleY(1);
						transition: .4s;
						opacity: .95;
					}
				}
			}
			
		}

		.projectOne {
			margin: 8px auto 0px;
		}

		.projectTwo {
			background: url(../assets/thumbs/fishLoop.gif) no-repeat;
		    background-size: cover;
		    background-position: center;

		    &.filterOn {
				border: 2px solid orange;
			}
		}

		.projectThree {
			background: url(../assets/thumbs/touchsource.jpg) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.projectFour {
			background: url(../assets/thumbs/aspens.jpg) no-repeat;
			background-size: cover;
		    background-position: center;
		    // @media (min-width: 1440px) {
		    // 	// background-size: 75%;
		    // }
		}

		.projectFive {
			background: url(../assets/thumbs/elderscrolls.jpg) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.projectSix {
			background: url(../assets/thumbs/caroLoop.gif) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.projectSeven {
			background: url(../assets/thumbs/fizzMap.jpg) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.projectEight {
			background: url(../assets/thumbs/mediacracy.jpg) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.projectNine {
			background: url(../assets/thumbs/starbucks.svg) no-repeat white;
			background-size: 60%;
		    background-position-x: 50%;

		    @media( min-width: 768px) {
				background-size: contain;
			}
		}

		.projectTen {
			background: url(../assets/thumbs/motionReel.gif) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.projectEleven {
			background: url(../assets/thumbs/intoTheStorm.jpg) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.projectTwelve {
			background: url(../assets/thumbs/heisman.jpg) no-repeat;
			background-size: cover;
		    background-position: 50% -50px;

		    @media( min-width: 1440px) {
				background-position: center;
			}
		}

		.projectThirteen {
			background: url(../assets/thumbs/thrones.jpg) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.projectFourteen {
			background: url(../assets/thumbs/bengal.jpg) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.projectFifteen {
			background: url(../assets/thumbs/sirius.jpg) no-repeat;
			background-size: cover;
		    background-position: center;
		}

		.small {
			@media( min-width: 768px) {
				width: 49.65%;
				margin: 8px 0 0 0;
			}

			@media( min-width: 1440px) {
				width: 49.75%;
			}
		}

		.nudgeLeft {
			@media( min-width: 1440px) {
				margin-left: .5%;
			}
		}

		.makeFullSize {
			@media( min-width: 768px) {
				width: 100%;
				transition: .5s;
			}
		}

		.hideDevProject {
			transition: .5s;
			opacity: 0;
			width: 0px !important;
			height: 0px !important;
		}

		.hideDesignProject {
			transition: .5s;
			opacity: 0;
			width: 0px !important;
			height: 0px !important;
		}

		.hideMusicProject {
			transition: .5s;
			opacity: 0;
			width: 0px !important;
			height: 0px !important;
		}
	}



	.contactButton {
	    position: relative;
	    margin: 50px auto 40px;
	    width: 49%;
	    height: 67px;
	    text-transform: uppercase;
	    text-align: center;
	    font-family: niveau-grotesk, sans-serif;
	    font-size: 25px;
	    font-weight: 500;
	    letter-spacing: 1px;
	    color: white;
	    border: 4px solid white;
	    cursor: pointer;

		@media (min-width: 768px) {
			width: 28%;
			font-size: 39px;
			height: 95px;
		}

		@media (min-width: 1440px) {
		    margin: 80px auto 200px;
			width: 17%;
			font-size: 47px;
			height: 121px;
			transition: .4s;

			&:hover {
				background: rgba(255, 255, 255, .4);
				transition: .4s;
			}
		}

		a {
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    padding-top: 16px;
		    top: 0;
		    left: 0;
		    color: white;
		    text-decoration: none;

			@media (min-width: 768px) {
				padding-top: 22px;
			}

			@media (min-width: 1440px) {
				padding-top: 34px;
			}
		}
	}
}




















